/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "enrollment/initialization-bff/api/v1/initialization_service.proto" (package "enrollment.initialization_bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { InitializationBffService } from "./initialization_service";
import type { Empty } from "../../../../google/protobuf/empty";
import type { UpdateMockCiamInfoRequest } from "./initialization_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { InitializeParticipantInfoResponse } from "./initialization_service";
import type { InitializeParticipantInfoRequest } from "./initialization_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service enrollment.initialization_bff.api.v1.InitializationBffService
 */
export interface IInitializationBffServiceClient {
    /**
     * NOTE: this also returns a JWT in the "authorization" metadata field
     *
     * @generated from protobuf rpc: InitializeParticipantInfo(enrollment.initialization_bff.api.v1.InitializeParticipantInfoRequest) returns (enrollment.initialization_bff.api.v1.InitializeParticipantInfoResponse);
     */
    initializeParticipantInfo(input: InitializeParticipantInfoRequest, options?: RpcOptions): UnaryCall<InitializeParticipantInfoRequest, InitializeParticipantInfoResponse>;
    /**
     * When mocking CIAM, this method is used to update the mock CIAM info returned.
     *
     * @generated from protobuf rpc: UpdateMockCiamInfo(enrollment.initialization_bff.api.v1.UpdateMockCiamInfoRequest) returns (google.protobuf.Empty);
     */
    updateMockCiamInfo(input: UpdateMockCiamInfoRequest, options?: RpcOptions): UnaryCall<UpdateMockCiamInfoRequest, Empty>;
}
/**
 * @generated from protobuf service enrollment.initialization_bff.api.v1.InitializationBffService
 */
export class InitializationBffServiceClient implements IInitializationBffServiceClient, ServiceInfo {
    typeName = InitializationBffService.typeName;
    methods = InitializationBffService.methods;
    options = InitializationBffService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * NOTE: this also returns a JWT in the "authorization" metadata field
     *
     * @generated from protobuf rpc: InitializeParticipantInfo(enrollment.initialization_bff.api.v1.InitializeParticipantInfoRequest) returns (enrollment.initialization_bff.api.v1.InitializeParticipantInfoResponse);
     */
    initializeParticipantInfo(input: InitializeParticipantInfoRequest, options?: RpcOptions): UnaryCall<InitializeParticipantInfoRequest, InitializeParticipantInfoResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitializeParticipantInfoRequest, InitializeParticipantInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * When mocking CIAM, this method is used to update the mock CIAM info returned.
     *
     * @generated from protobuf rpc: UpdateMockCiamInfo(enrollment.initialization_bff.api.v1.UpdateMockCiamInfoRequest) returns (google.protobuf.Empty);
     */
    updateMockCiamInfo(input: UpdateMockCiamInfoRequest, options?: RpcOptions): UnaryCall<UpdateMockCiamInfoRequest, Empty> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMockCiamInfoRequest, Empty>("unary", this._transport, method, opt, input);
    }
}
