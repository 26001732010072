// The 'URL' is not supported until Node.js 10.0.0. The configured version range is '>=8.0.0'. Once
// Node is updated (2018+), use this cleaner getInternalUrl function:
// export function getInternalUrl(url: string): string {
//   const internalDomain = 'app.verilyme.com';
//   const urlObj = new URL(url);

//   if (urlObj.hostname.includes(internalDomain)) {
//     return urlObj.pathname + urlObj.search + urlObj.hash;
//   }
//   return '';
// }

/**
 * Determines whether the URL given is an internal Verily Me Web URL. If so, returns the relative
 * URL slug.
 *
 * @param {string} url - The URL to check.
 *
 * @returns {string} - Returns relative internal URL slug, otherwise empty string for external URL.
 */
export function getInternalUrl(url: string): string {
  // Short-circuit for relative URL
  if (url.startsWith('/')) {
    return url;
  }

  const internalDomain = 'app.verilyme.com';
  const regex = new RegExp('^(https?://)?([^/?#]+)([^?#]*)(\\?[^#]*)?(#.*)?$');
  const matches = url.match(regex);

  if (matches) {
    const hostname = matches[2];
    const pathname = matches[3] || '';
    const search = matches[4] || '';
    const hash = matches[5] || '';

    if (hostname.includes(internalDomain)) {
      if (pathname === '') {
        return '/';
      }
      return pathname + search + hash;
    }
  }
  return '';
}
