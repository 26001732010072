import {Box, CircularProgress} from '@mui/material';

const Recruitment = () => {
  return (
    <Box
      data-testid="loading"
      sx={{
        position: 'absolute',
        inset: '0',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <CircularProgress aria-label="loading circle" />
    </Box>
  );
};
export default Recruitment;
