import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';

export default async (): Promise<RpcOptions> => {
  if (PHAF_INJECTED_VALUES.IS_BDD_TEST) {
    return {};
  }
  const interceptor = await Auth.getInterceptor();
  const options: RpcOptions = {
    interceptors: [interceptor],
  };
  return options;
};
